.wrapper {
	position: absolute;
	z-index: 1;
	display: block;
	height: 24px;
	width: calc(100% - 48px);
}

.searchbar {
	--background: transparent;
	--placeholder-color: var(--ion-placeholder-color);
	--placeholder-opacity: 1;
	padding-bottom: 0px !important;
	height: 24px !important;
	opacity: 0;
	/* 
		DO NOT USE the transition on visiblity
		Because while input is in the transition from hidden to visible
		it is not focusable so the transition will break our focus managment 
		(when we open search focus should be placed on the input)
	*/
	transition: opacity 0.4s;
	visibility: hidden;
	font-size: var(--text-font-size-lg);
}

.searchbar.isVisible {
	opacity: 1;
	visibility: visible;
}

.searchbar [class~="searchbar-input-container"] {
	height: 24px;
}

.searchbar input {
	font-size: inherit !important;
	font-weight: inherit !important;
}

.searchbar [class~="searchbar-search-icon"] {
	display: none !important;
}

.open_button,
.close_button {
	display: block;
	border: 0px;
	padding-top: 0px;
	padding-bottom: 0px;
	line-height: 0px;
	appearance: none;
	cursor: pointer;
	background-color: transparent;
}

.open_button {
	position: absolute;
	top: 50%;
	left: 100%;
	transition: 0.4s;
	transform: translate(-100%, -50%);
}

.open_button.alignedLeft {
	left: 0%;
	transform: translate(0%, -50%);
	opacity: 1;
}

.close_button {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	transition: 0.4s;
}

.close_button:not(.isVisible) {
	opacity: 0;
	visibility: hidden;
}
