.topToolbar {
	--background: transparent;
	--border-color: transparent;
	--border-width: 0px;
	--color: var(--ion-color-light);
	--ion-toolbar-color: var(--ion-color-light);
}

.topToolbar::after {
	display: block;
	width: calc(100% - 24px);
	height: 2px;
	float: right;
	background-color: var(--ion-color-light);
	content: '';
}

@media (prefers-color-scheme: dark) {
	.topToolbar {
		--color: var(--ion-color-dark);
		--ion-toolbar-color: var(--ion-color-dark);
	}
}

.content {
	--padding-start: 0px;
	--padding-end: 0px;
	--padding-top: 0px;
	--padding-bottom: 0px;
}

.content::part(scroll) {
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
}

.content::part(background) {
	background-size: cover;
}
/* todo firefox backdrop-filter fallback */
.content.hasVideo::part(scroll) {
	backdrop-filter: blur(25px);
	backface-visibility: hidden;
}

.mediaPlayerControls {
	display: grid;
	grid-template-columns: auto 1fr auto;
	gap: 11px;
	align-items: center;
}

.playHeadPositionWrapper {
	width: 40px;
	white-space: nowrap;
}

.bottomSheet [data-rsbs-backdrop],
.bottomSheet [data-rsbs-overlay],
.bottomSheet [data-rsbs-root]:after {
	z-index: 0;
	-ms-scroll-chaining: none;
	overscroll-behavior: none;
	touch-action: none;
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
}

.bottomSheet [data-rsbs-overlay] {
	border-top-left-radius: 24px;
	border-top-right-radius: 24px;
	display: flex;
	background: var(--ion-background-color);
	color: var(--ion-text-color);
	flex-direction: column;
	height: var(--rsbs-overlay-h);
	will-change: height;
	bottom: 64px;
	filter: drop-shadow(0px -8px 16px #00000014);
	transform: translateY(0%);
	min-height: 86px;
	transition: transform 400ms;
	transform: translate3d(0, 0, 0);
}

.bottomSheet.isHidden [data-rsbs-overlay] {
	/* translate y: bottom sheet height + bottom offset */
	transform: translate3d(0, calc(100% + 64px), 0);
}

.bottomSheet [data-rsbs-header]::before {
	background-color: var(--ion-color-medium);
	border-radius: 1px;
	content: "";
	display: block;
	height: 2px;
	left: 50%;
	position: absolute;
	top: 8px;
	transform: translateX(-50%);
	width: 48px;
}

.bottomSheet [data-rsbs-scroll] {
	overflow-y: scroll;
}

.bottomSheet [data-rsbs-scroll]::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.bottomSheet [data-rsbs-scroll] {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.bottomSheetHeaderContent {
	padding-top: 24px;
	padding-bottom: 16px;
	padding-left: 24px;
	padding-right: 24px;
}

.bottomSheetScrollContent {
	padding-top: 0;
	padding-bottom: 24px;
	padding-left: 24px;
	padding-right: 24px;
}

.bottomSheetScrollContent.hasNoContent {
	/* 
		bottom sheet is hidden for some reason
		when padding bottom is set to 0px
	*/
	padding-bottom: 1px;
}

.title {
	display: block;
	font-size: var(--text-font-size-2xl);
	font-weight: var(--font-weight-bold);
	line-height: 1;
	margin: 0;
}

.artistName {
	display: block;
	font-size: var(--text-font-size);
	font-weight: var(--font-weight-bold);
	font-style: italic;
	line-height: 1;
	margin: 0;
	margin-top: 6px;
	letter-spacing: var(--letter-spacing-secondary);
}

.description {
	margin: 0;
	padding: 0;
	line-height: var(--line-height);
	color: var(--ion-color-step-600);
}

@media (prefers-color-scheme: dark) {
	.description {
		color: var(--ion-color-step-500);
	}
}

.likeButton {
	position: relative;
}

/* Layer icon to create fill effect for the outline icon */
/* on buttons "liked" state changes */
.likedIcon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) scale(0);
	transition: 0.2s;
	opacity: 0.25;
}

/* Fill the outlined icon */
.likeButton.isLiked .likedIcon {
	transform: translate(-50%, -50%) scale(1);
	opacity: 1;
}

.footer {
	box-shadow: 0px -8px 16px rgba(0, 0, 0, 0.08);
	position: absolute;
	bottom: 0;
	transition: 400ms;
	transform: translate3d(0, 0, 0);
}

.footer.isHidden {
	transform: translate3d(0, 100%, 0);
}

@media (prefers-color-scheme: dark) {
	.footer {
		box-shadow: 0px -8px 16px rgba(0, 0, 0, 0.72);
	}
}
