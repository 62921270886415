.tour {
	display: block;
	width: 100%;
	padding-top: 100%;
	position: relative;
	box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.24);
	border-radius: 16px;
	overflow: hidden;
}

.tour.isLg {
	padding-top: 47.435%;
	box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
}

@media (prefers-color-scheme: dark) {
	.tour,
	.tour.isLg {
		box-shadow: 0px 8px 16px rgba(0, 0, 0, 1);
	}
}

.cover {
	bottom: 0;
	display: block;
	height: 100%;
	left: 0;
	object-fit: cover;
	object-position: center;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
}

.textWrapper {
	bottom: 8px;
	left: 8px;
	position: absolute;
	right: 8px;
	z-index: 10;
	padding: 12px;
	background-color: var(--ion-background-color);
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

/* Add background blur effect */
@supports (-webkit-backdrop-filter: blur(8px)) or (backdrop-filter: blur(8px)) {
	.textWrapper {
		background-color: rgba(var(--ion-background-color-rgb), 0.48);
		backdrop-filter: blur(8px);
		-webkit-backdrop-filter: blur(8px);
	}
}

.name {
	color: var(--ion-text-color);
	font-weight: var(--font-weight-bold);
	display: block;
	font-size: var(--text-font-size-sm);
	line-height: 1;
	margin: 0;
	letter-spacing: var(--letter-spacing-secondary);
	margin-right: 12px;
	overflow: hidden;
}

.tour.isLg .name {
	font-size: var(--text-font-size);
	line-height: 1;
}
