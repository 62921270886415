/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */
@import "./fonts/stylesheet.css";

/** Ionic CSS Variables **/
:root {
	/* Custom variables */
	--font-family-primary: "SF UI Display", Helvetica, "Helvetica Neue", Aria, sans-serif;
	--font-family-secondary: var(--font-family-primary);
	--font-weight-normal: normal;
	--font-weight-bold: bold;
	--font-weight-bolder: 900;
	--letter-spacing: 0em;
	--letter-spacing-secondary: 0em;
	--text-font-size-3xs: 10px;
	--text-font-size-2xs: 12px;
	--text-font-size-xs: 13px;
	--text-font-size-sm: 14px;
	--text-font-size: 18px;
	--text-font-size-lg: 18px;
	--text-font-size-xl: 22px;
	--text-font-size-2xl: 24px;
	--text-font-size-3xl: 26px;
	--text-font-size-4xl: 32px;
	--line-height: 1.5rem;
	--line-height-secondary: 1.5;
	--strong-text-font-size: var(--text-font-size);
	--icon-font-size-small: 16px;
	--icon-font-size-medium: 24px;
	--icon-font-size-large: 32px;

	/*
		Application variables
	*/
	--ion-font-family: var(--font-family-primary);

	/*
		Application colors
	*/
	--ion-background-color: #EBEBE6;
	--ion-background-color-rgb: 255, 255, 255;
	--ion-text-color: #191919;
	--ion-text-color-rgb: 25, 25, 25;
	--ion-border-color: var(--ion-color-step-100);
	--ion-tab-bar-background: var(--ion-color-dark);
	--ion-tab-bar-background-focused: var(--ion-color-dark-tint);
	--ion-tab-bar-border-color: var(--ion-color-dark);
	--ion-tab-bar-color: rgba(var(--ion-color-dark-contrast-rgb), 0.5);
	--ion-tab-bar-color-selected: var(--ion-color-dark-contrast);
	--ion-toolbar-background: var(--ion-background-color);
	--ion-toolbar-border-color: var(--ion-background-color);
	--ion-toolbar-color: var(--ion-text-color);
	--ion-placeholder-color: var(--ion-color-step-600);

	/* 
		Stepped colors
	*/
	--ion-color-step-50: #f4f4f4;
	--ion-color-step-100: #e8e8e8;
	--ion-color-step-150: #dddddd;
	--ion-color-step-200: #d1d1d1;
	--ion-color-step-250: #c6c6c6;
	--ion-color-step-300: #bababa;
	--ion-color-step-350: #afafaf;
	--ion-color-step-400: #a3a3a3;
	--ion-color-step-450: #989898;
	--ion-color-step-500: #8c8c8c;
	--ion-color-step-550: #818181;
	--ion-color-step-600: #757575;
	--ion-color-step-650: #6a6a6a;
	--ion-color-step-700: #5e5e5e;
	--ion-color-step-750: #535353;
	--ion-color-step-800: #474747;
	--ion-color-step-850: #3c3c3c;
	--ion-color-step-900: #303030;
	--ion-color-step-950: #252525;

	/* 
		Theme colors
	*/
	--ion-color-primary: #23d7b4;
	--ion-color-primary-rgb: 35, 215, 180;
	--ion-color-primary-contrast: #EBEBE6;
	--ion-color-primary-contrast-rgb: 235, 235, 230;
	--ion-color-primary-shade: #1fbd9e;
	--ion-color-primary-tint: #39dbbc;

	--ion-color-secondary: #3dc2ff;
	--ion-color-secondary-rgb: 61, 194, 255;
	--ion-color-secondary-contrast: #EBEBE6;
	--ion-color-secondary-contrast-rgb: 235, 235, 230;
	--ion-color-secondary-shade: #36abe0;
	--ion-color-secondary-tint: #50c8ff;

	--ion-color-tertiary: #6a64ff;
	--ion-color-tertiary-rgb: 106, 100, 255;
	--ion-color-tertiary-contrast: #EBEBE6;
	--ion-color-tertiary-contrast-rgb: 235, 235, 230;
	--ion-color-tertiary-shade: #5d58e0;
	--ion-color-tertiary-tint: #7974ff;

	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45, 211, 111;
	--ion-color-success-contrast: #EBEBE6;
	--ion-color-success-contrast-rgb: 235, 235, 230;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255, 196, 9;
	--ion-color-warning-contrast: #191919;
	--ion-color-warning-contrast-rgb: 25, 25, 25;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235, 68, 90;
	--ion-color-danger-contrast: #EBEBE6;
	--ion-color-danger-contrast-rgb: 235, 235, 230;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	--ion-color-dark: #191919;
	--ion-color-dark-rgb: 25, 25, 25;
	--ion-color-dark-contrast: #EBEBE6;
	--ion-color-dark-contrast-rgb: 235, 235, 230;
	--ion-color-dark-shade: #161616;
	--ion-color-dark-tint: #303030;

	--ion-color-medium: #bababa;
	--ion-color-medium-rgb: 186, 186, 186;
	--ion-color-medium-contrast: #191919;
	--ion-color-medium-contrast-rgb: 25, 25, 25;
	--ion-color-medium-shade: #a4a4a4;
	--ion-color-medium-tint: #c1c1c1;

	--ion-color-light: #EBEBE6;
	--ion-color-light-rgb: 235, 235, 230;
	--ion-color-light-contrast: #191919;
	--ion-color-light-contrast-rgb: 25, 25, 25;
	--ion-color-light-shade: #EBEBE6;
	--ion-color-light-tint: #EBEBE6;
}

@media (prefers-color-scheme: dark) {
	/*
   * Dark Colors
   * -------------------------------------------
   */

	body {
		/*
			Application colors
		*/
		--ion-background-color: #ffffff;
		--ion-background-color-rgb: 255, 255, 255;
		--ion-text-color: #191919;
		--ion-text-color-rgb: 25, 25, 25;
		--ion-border-color: var(--ion-color-step-200);
		--ion-tab-bar-background: var(--ion-background-color);
		--ion-tab-bar-background-focused: var(--ion-color-light-shade);
		--ion-tab-bar-border-color: var(--ion-background-color);
		--ion-tab-bar-color: var(--ion-text-color);
		--ion-tab-bar-color-selected: var(--ion-color-primary);
		--ion-toolbar-background: var(--ion-background-color);
		--ion-toolbar-border-color: var(--ion-background-color);
		--ion-toolbar-color: var(--ion-text-color);
		--ion-placeholder-color: var(--ion-color-step-600);

		/**
			Theme colors
		*/
		--ion-color-primary: #23d7b4;
		--ion-color-primary-rgb: 35, 215, 180;
		--ion-color-primary-contrast: #191919;
		--ion-color-primary-contrast-rgb: 255, 255, 255;
		--ion-color-primary-shade: #1fbd9e;
		--ion-color-primary-tint: #39dbbc;

		--ion-color-secondary: #50c8ff;
		--ion-color-secondary-rgb: 80, 200, 255;
		--ion-color-secondary-contrast: #ffffff;
		--ion-color-secondary-contrast-rgb: 255, 255, 255;
		--ion-color-secondary-shade: #46b0e0;
		--ion-color-secondary-tint: #62ceff;

		--ion-color-tertiary: #6a64ff;
		--ion-color-tertiary-rgb: 106, 100, 255;
		--ion-color-tertiary-contrast: #ffffff;
		--ion-color-tertiary-contrast-rgb: 255, 255, 255;
		--ion-color-tertiary-shade: #5d58e0;
		--ion-color-tertiary-tint: #7974ff;

		--ion-color-success: #2fdf75;
		--ion-color-success-rgb: 47, 223, 117;
		--ion-color-success-contrast: #000000;
		--ion-color-success-contrast-rgb: 0, 0, 0;
		--ion-color-success-shade: #29c467;
		--ion-color-success-tint: #44e283;

		--ion-color-warning: #ffd534;
		--ion-color-warning-rgb: 255, 213, 52;
		--ion-color-warning-contrast: #000000;
		--ion-color-warning-contrast-rgb: 0, 0, 0;
		--ion-color-warning-shade: #e0bb2e;
		--ion-color-warning-tint: #ffd948;

		--ion-color-danger: #ff4961;
		--ion-color-danger-rgb: 255, 73, 97;
		--ion-color-danger-contrast: #ffffff;
		--ion-color-danger-contrast-rgb: 255, 255, 255;
		--ion-color-danger-shade: #e04055;
		--ion-color-danger-tint: #ff5b71;

		--ion-color-dark: #ffffff;
		--ion-color-dark-rgb: 255, 255, 255;
		--ion-color-dark-contrast: #191919;
		--ion-color-dark-contrast-rgb: 0, 0, 0;
		--ion-color-dark-shade: #e0e0e0;
		--ion-color-dark-tint: #ffffff;

		--ion-color-medium: #474747;
		--ion-color-medium-rgb: 71, 71, 71;
		--ion-color-medium-contrast: #ffffff;
		--ion-color-medium-contrast-rgb: 255, 255, 255;
		--ion-color-medium-shade: #3e3e3e;
		--ion-color-medium-tint: #595959;

		--ion-color-light: #191919;
		--ion-color-light-rgb: 25, 25, 25;
		--ion-color-light-contrast: #ffffff;
		--ion-color-light-contrast-rgb: 255, 255, 255;
		--ion-color-light-shade: #161616;
		--ion-color-light-tint: #303030;
	}

	/*
   * iOS Dark Theme
   * -------------------------------------------
   */

	.ios body {
		/*
			Application colors
		*/
		--ion-background-color: #191919;
		--ion-background-color-rgb: 25, 25, 25;
		--ion-text-color: #ffffff;
		--ion-text-color-rgb: 255, 255, 255;
		--ion-border-color: var(--ion-color-step-200);

		/*
			Stepped colors
		*/
		--ion-color-step-50: #252525;
		--ion-color-step-100: #303030;
		--ion-color-step-150: #3c3c3c;
		--ion-color-step-200: #474747;
		--ion-color-step-250: #535353;
		--ion-color-step-300: #5e5e5e;
		--ion-color-step-350: #6a6a6a;
		--ion-color-step-400: #757575;
		--ion-color-step-450: #818181;
		--ion-color-step-500: #8c8c8c;
		--ion-color-step-550: #989898;
		--ion-color-step-600: #a3a3a3;
		--ion-color-step-650: #afafaf;
		--ion-color-step-700: #bababa;
		--ion-color-step-750: #c6c6c6;
		--ion-color-step-800: #d1d1d1;
		--ion-color-step-850: #dddddd;
		--ion-color-step-900: #e8e8e8;
		--ion-color-step-950: #f4f4f4;

		--ion-item-background: #191919;

		--ion-card-background: #191919;
		--ion-toolbar-background: #191919;
	}

	.ios ion-modal {
		--ion-background-color: var(--ion-color-step-100);
		--ion-toolbar-background: var(--ion-color-step-150);
		--ion-toolbar-border-color: var(--ion-color-step-250);
	}

	/*
   * Material Design Dark Theme
   * -------------------------------------------
   */

	.md body {
		/*
			Application colors
		*/
		--ion-background-color: #191919;
		--ion-background-color-rgb: 25, 25, 25;
		--ion-text-color: #ffffff;
		--ion-text-color-rgb: 255, 255, 255;
		--ion-border-color: var(--ion-color-step-200);

		/*
			Stepped colors
		*/
		--ion-color-step-50: #252525;
		--ion-color-step-100: #303030;
		--ion-color-step-150: #3c3c3c;
		--ion-color-step-200: #474747;
		--ion-color-step-250: #535353;
		--ion-color-step-300: #5e5e5e;
		--ion-color-step-350: #6a6a6a;
		--ion-color-step-400: #757575;
		--ion-color-step-450: #818181;
		--ion-color-step-500: #8c8c8c;
		--ion-color-step-550: #989898;
		--ion-color-step-600: #a3a3a3;
		--ion-color-step-650: #afafaf;
		--ion-color-step-700: #bababa;
		--ion-color-step-750: #c6c6c6;
		--ion-color-step-800: #d1d1d1;
		--ion-color-step-850: #dddddd;
		--ion-color-step-900: #e8e8e8;
		--ion-color-step-950: #f4f4f4;

		--ion-item-background: #191919;

		--ion-toolbar-background: #191919;

		--ion-tab-bar-background: #191919;

		--ion-card-background: #191919;
	}
}
