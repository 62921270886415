.grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-auto-flow: dense;
	gap: 16px;
}

.gridItem {
	grid-column: span 1;
}

.gridItem.isExpanded {
	grid-column: 1/-1;
}
