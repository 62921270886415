.list {
	list-style: none;
	display: block;
	margin: 0;
	padding: 0;
}

.item {
	display: block;
	margin: 0;
	padding: 0;
}

.button {
	--border-radius: 16px;
	--padding-top: 8px;
	--padding-bottom: 8px;
	--padding-start: 0px;
	--padding-end: 0px;
	height: 3em;
	margin: 0;
}

.button ion-icon[slot="start"] {
	margin-left: 0;
}

.button ion-icon[slot="end"] {
	margin-right: 4px;
}

.languageIcon {
	font-size: 32px;
	margin-right: 12px;
	height: 32px;
	border-radius: 8px;
}

.languageName {
	margin-right: auto;
}
