.body {
	text-align: left;
}

.body *:not(h1, h2, h3, h4, h5, h6, strong) {
	color: var(--ion-color-step-600);
}

@media (prefers-color-scheme: dark) {
	.body *:not(h1, h2, h3, h4, h5, h6, strong) {
		color: var(--ion-color-step-500);
	}
}

.body h1,
.body h2,
.body h3,
.body h4,
.body h5,
.body h6 {
	margin: 16px 0 8px 0;
	line-height: var(--line-height-secondary);
}

.body h1 {
	font-size: var(--text-font-size-3xl);
}

.body h2 {
	font-size: var(--text-font-size-xl);
}

.body h3 {
	font-size: var(--text-font-size-lg);
}

.body h4 {
	font-size: var(--text-font-size);
}

.body h5 {
	font-size: var(--text-font-size-2xs);
}

.body h6 {
	font-size: var(--text-font-size-3xs);
}

.body h1:first-child,
.body h2:first-child,
.body h3:first-child,
.body h4:first-child,
.body h5:first-child,
.body h6:first-child {
	margin-top: 0;
}

.body p {
	margin: 0 0 2px 0;
}

.body strong {
	color: var(--ion-text-color);
	font-weight: var(--font-weight-bold);
	letter-spacing: var(--letter-spacing-secondary);
}

.body ul,
.body ol {
	list-style-type: none;
	margin: 8px 0;
	padding: 0;
	box-sizing: border-box;
	line-height: var(--line-height);
}

.body li {
	padding: 2px 0 2px 12px;
}

.body li::before {
	display: inline-block;
	letter-spacing: 0;
	margin-left: -12px;
	width: 12px;
	color: inherit;
	content: "•";
}

.body a {
	font-size: var(--strong-text-font-size);
	font-weight: var(--font-weight-bold);
	text-decoration: none;
	color: var(--ion-color-primary);
	letter-spacing: var(--letter-spacing-secondary);
}
