.list {
	display: grid;
	row-gap: 16px;
	grid-template-columns: minmax(0, 1fr);
}

.stopLink {
	display: block;
	color: inherit;
	text-decoration: none;
}

.title {
	transition: 0.4s;
	transition-delay: 0.02s;
}

.title.isHidden {
	transform: translate3d(-24px, 0, 0);
	opacity: 0;
	visibility: hidden;
}

.headerButtons {
	transition: 0.4s;
}

.headerButtons.isHidden {
	transform: translate3d(-12px, 0, 0);
	opacity: 0;
	visibility: hidden;
	/* 
		shrink buttons container to allow
		the search bar span full width
		of the ion toolbar
	*/
	max-width: 0px;
}
