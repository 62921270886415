@font-face {
    font-family: 'SF UI Display';
    src: url('SFUIDisplay-Light.woff2') format('woff2'),
        url('SFUIDisplay-Light.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF UI Display';
    src: url('SFUIDisplay-Bold.woff2') format('woff2'),
        url('SFUIDisplay-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF UI Display';
    src: url('SFUIDisplay-Black.woff2') format('woff2'),
        url('SFUIDisplay-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF UI Display';
    src: url('SFUIDisplay-Heavy.woff2') format('woff2'),
        url('SFUIDisplay-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF UI Display';
    src: url('SFUIDisplay-Regular.woff2') format('woff2'),
        url('SFUIDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF UI Display';
    src: url('SFUIDisplay-Medium.woff2') format('woff2'),
        url('SFUIDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF UI Display';
    src: url('SFUIDisplay-Thin.woff2') format('woff2'),
        url('SFUIDisplay-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF UI Display';
    src: url('SFUIDisplay-Semibold.woff2') format('woff2'),
        url('SFUIDisplay-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

