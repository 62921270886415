.container {
	display: block;
	width: 100%;
	min-height: 100%;
	max-width: 264px;
	margin-left: auto;
	margin-right: auto;
}

.output {
	align-items: center;
	display: flex;
	position: relative;
	margin-bottom: 24px;
	border-radius: 48px;
	padding-left: 28px;
	padding-right: 12px;
	height: 72px;
	border: 2px solid var(--ion-border-color);
}

.invalidNumberErrorMessage {
	margin: 0;
	font-size: var(--text-font-size-sm);
	font-weight: var(--font-weight-bold);
	letter-spacing: var(--letter-spacing-secondary);
	line-height: 1;
}

.typedNumber {
	font-size: var(--text-font-size-4xl);
	font-weight: var(--font-weight-bold);
	letter-spacing: var(--letter-spacing-secondary);
	line-height: 1;
	overflow-x: scroll;
	white-space: nowrap;
	margin-right: 12px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.typedNumber::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.typedNumber {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.playButton {
	--padding-end: 0px;
	--padding-start: 0px;
	height: 48px;
	width: 48px;
	margin-left: auto;
	flex-shrink: 0;
}

.playButton.isDisabled {
	--box-shadow: 0;
	--background: var(--ion-color-step-200);
	opacity: 1;
}

.keypad {
	display: grid;
	column-gap: 24px;
	row-gap: 16px;
	grid-template-columns: repeat(3, 72px);
}

.keypadButton {
	--padding-end: 0px;
	--padding-start: 0px;
	--box-shadow: 0px 8px 16px #0000001f;
	font-size: var(--text-font-size-4xl);
	height: 72px;
	width: 72px;
	margin: 0;
	letter-spacing: var(--letter-spacing-secondary);
}

.keypadButton ion-icon {
	font-size: var(--icon-font-size-large);
}

.removeDigitButton {
	grid-row: 4;
	grid-column: 1;
}

.clearButton {
	grid-row: 4;
	grid-column: 3;
}
