.preview {
	display: flex;
	width: 100%;
	min-height: 96px;
	box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
	border-radius: 16px;
	padding: 8px;
}

@media (prefers-color-scheme: dark) {
	.preview {
		display: flex;
		width: 100%;
		min-height: 96px;
		box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.8);
		border-radius: 16px;
		padding: 8px;
	}
}

.thumbnail {
	width: 80px;
	height: 80px;
	object-fit: cover;
	object-position: center;
	border-radius: 8px;
	flex-shrink: 0;
	flex-grow: 0;
}

.body {
	padding-left: 12px;
	flex-grow: 1;
	flex-shrink: 1;
	display: flex;
	flex-direction: column;
	padding-top: 8px;
	min-width: 0;
}

.title {
	font-size: var(--text-font-size);
	font-weight: var(--font-weight-bold);
	line-height: 1;
	letter-spacing: var(--letter-spacing-secondary);
	text-align: left;
	margin: 0;
}

.artistName {
	color: var(--ion-color-step-600);
	font-size: var(--text-font-size-sm);
	line-height: 1;
	letter-spacing: var(--letter-spacing);
	margin-top: 6px;
	margin-bottom: 0;
	margin-left: 0;
	margin-right: 0;
}

@media (prefers-color-scheme: dark) {
	.artistName {
		color: var(--ion-color-step-500);
	}
}

.meta {
	display: flex;
	align-items: flex-start;
	margin-top: auto;
}

.duration {
	align-items: center;
	display: inline-flex;
	margin: 0;
}

.durationText {
	display: block;
	font-size: var(--text-font-size-2xs);
	font-weight: var(--font-weight-bold);
	letter-spacing: var(--letter-spacing-secondary);
	line-height: 1;
	margin-left: 6px;
}

.number {
	margin-bottom: 0;
	margin-right: 0;
	margin-top: 0;
	background-color: var(--ion-color-dark);
	color: var(--ion-color-dark-contrast);
	border-radius: 8px;
	display: block;
	font-size: var(--text-font-size-2xs);
	font-weight: var(--font-weight-bold);
	letter-spacing: var(--letter-spacing-secondary);
	line-height: 24px;
	margin-left: auto;
	text-align: center;
	width: 28px;
}
